import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import SuperChat from "../../domain/superChat"
import "./styles.scss"

const index = ({ location }) => {
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout selectedPage={<SuperChat location={location} />} />
    </div>
  )
}

export default index
