import React, { useState, useEffect } from "react"

import { getAllConversation } from "../../../services/user"
import { Table } from "antd"
import { navigate } from "gatsby"
import { itemRender } from "../../../customUtil/table"
import moment from "moment"

import queryString from "query-string"

const TableListContact = ({ location }) => {
  const [listSeller, setListSeller] = useState(null)

  const [loading, setLoading] = useState(false)

  const [total, setTotal] = useState(0)
  const [tableParams, setTableParams] = useState(null)

  const handleGetListData = async () => {
    setLoading(true)
    const { response } = await getAllConversation({
      store_id: "",
      // q: "株式会社ONE.course",

      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
    })
    if (response.status == 200) {
      const query = queryString.parse(location.search)
      if (
        response?.data?.total &&
        Math.ceil(response?.data?.total / 10) < Number(query?.pageCurrent)
      ) {
        const newQueryStr = queryString.stringify({
          ...query,
          pageCurrent: 1,
        })
        navigate(
          `/super-chat/?${queryString.stringify(newQueryStr)}`,
          undefined,
          {
            shallow: false,
          }
        )
        return
      }
      setTotal(response?.data?.total)
      setListSeller(response.data?.result)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (tableParams) {
      handleGetListData()
    }
  }, [tableParams])
  const columns = [
    {
      title: "",
      width: 40,

      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              color: "white",
              backgroundColor: "#E60020",
              padding: "0 4px",
              marginRight: 5,
              borderRadius: "2px",
            }}
          >
            {record.totalMessage - record.adminSeen > 0 ? "NEW" : ""}
          </span>
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          注文番号
        </div>
      ),
      width: 100,

      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{ color: "#888888", textAlign: "center", marginRight: 4 }}
          >
            {record.id}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          問合せ日時
        </div>
      ),

      width: 140,
      render: (_, record) => (
        <div style={{ color: "#888888", textAlign: "center" }}>
          {moment(record?.created_at).format("YYYY/MM/DD HH:mm")}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          出品企業
        </div>
      ),
      width: 200,

      render: (_, record) => (
        <div style={{ color: "#222222" }}>{record?.storePartner?.name}</div>
      ),
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          アカウント名
        </div>
      ),

      render: (_, record) => (
        <div
          style={{
            color: "#222222",
            display: "flex",
          }}
        >
          <span style={{ marginRight: 4 }}>
            {record?.metadata?.userSendLast}
          </span>
          {/* <Badge count={data.totalMessage - data.userSeen} /> */}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          メッセージ
        </div>
      ),

      render: (_, record) => (
        <div
          style={{
            color: "#222222",
          }}
        >
          <span style={{ marginRight: 4 }}>{record?.lastMessage}</span>
          {/* <Badge count={data.totalMessage - data.userSeen} /> */}
        </div>
      ),
    },
  ]
  const handleTableChange = (pagination, filters, sorter) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: pagination.current,
    }
    delete newQueryStr.sorterField
    delete newQueryStr.order

    if (sorter.order) {
      newQueryStr.sorterField = sorter.field
      newQueryStr.order = sorter.order
    }
    navigate(`/super-chat/?${queryString.stringify(newQueryStr)}`, undefined, {
      shallow: false,
    })
  }
  useEffect(() => {
    const query = queryString.parse(location.search)
    if (
      Number(query?.pageSize) &&
      Number(query?.pageCurrent) &&
      query?.pageSize == "10"
    ) {
      setTableParams({
        pagination: {
          current: Number(query?.pageCurrent),
          pageSize: 10,
        },
      })
    } else {
      const newQueryStr = queryString.stringify({
        pageSize: 10,
        pageCurrent: 1,
      })
      navigate(`/super-chat/?${newQueryStr}`)
    }
  }, [location])
  return (
    <div>
      {tableParams && (
        <Table
          locale={{ emptyText: "データなし" }}
          style={{ cursor: "pointer" }}
          columns={columns}
          dataSource={listSeller}
          loading={loading}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                navigate(`/super-chat/chat?store_id=${record?.buyer_id}`)
              },
            }
          }}
          rowKey={(data) => data.order_id}
          onChange={handleTableChange}
          pagination={{
            ...tableParams.pagination,

            total: total,
            itemRender: itemRender,
            showSizeChanger: false,
            showTotal: (total, range) => (
              <div className="w-full">
                {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                {total}件)
              </div>
            ),
          }}
        />
      )}
    </div>
  )
}

export default TableListContact
